import { flatten } from 'flat';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import English from './translations/en';
import Hindi from './translations/hi';
import Malayalam from './translations/ml';
import Tamil from './translations/ta';
import Telugu from './translations/te';

interface I18nProviderTypes {
  children: ReactNode;
}

const I18nProvider = ({ children }: I18nProviderTypes): JSX.Element => {
  const { locale } = useRouter();

  const messages = useMemo(() => {
    switch (locale) {
      case 'hi':
        return Hindi;
      case 'ml':
        return Malayalam;
      case 'ta':
        return Tamil;
      case 'te':
        return Telugu;
      default:
        return English;
    }
  }, [locale]);

  return (
    <IntlProvider
      locale={locale as unknown as string}
      messages={flatten(messages)}
    >
      {children}
    </IntlProvider>
  );
};

export default I18nProvider;
