import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import type { AppDispatch, RootState } from './store';

// export const useAppDispatch = () => useDispatch<AppDispatch>;
// export const useAppDispatch: () => AppDispatch = () =>
//   useDispatch<AppDispatch>();
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
// export const useAppSelector = <TSelected = unknown>(
//   selector: (state: RootState) => TSelected
// ): TSelected => useSelector<RootState, TSelected>(selector);

export const useAppDispatch: (arg?: any) => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
