// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBdyccl4yhmAvzQuFVCtsWd2A0y7XdXNGM',
  authDomain: 'thandhu-c5ae8.firebaseapp.com',
  projectId: 'thandhu-c5ae8',
  storageBucket: 'thandhu-c5ae8.appspot.com',
  messagingSenderId: '487169615519',
  appId: '1:487169615519:web:b18ebf77007faf28a9e4aa',
  measurementId: 'G-MTNFRBZFZF',
};

export default firebaseConfig;
