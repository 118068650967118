export interface CategoryType {
  name: string;
  id: string;
}

const categories: CategoryType[] = [
  {
    name: 'Astrology',
    id: 'astrology',
  },
  {
    name: 'Finance',
    id: 'finance',
  },
  {
    name: 'Sports',
    id: 'sports',
  },
  {
    name: 'Productivity',
    id: 'productivity',
  },
];

export default categories;
