import { Telugu as LoanEmiCalculatorI18n } from '@/apps/loan-emi-calculator/i18n';
import { Telugu as RunningPaceSplitCalculatorI18n } from '@/apps/running-pace-split-calculator/i18n';
import { Telugu as SashtashtagaDoshaCalculatorI18n } from '@/apps/sashtashtaga-dosha-calculator/i18n';
import { Telugu as ScreenRecorderI18n } from '@/apps/screen-recorder/i18n';
import { Telugu as ThirumanaPoruthamI18n } from '@/apps/thirumana-porutham-calculator/i18n';

const telugu = {
  app: {
    thirumanaPoruthamCalculator: ThirumanaPoruthamI18n,
    sashtashtagaDoshaCalculator: SashtashtagaDoshaCalculatorI18n,
    loanEmiCalculator: LoanEmiCalculatorI18n,
    screenRecorder: ScreenRecorderI18n,
    runningPaceSplitCalculator: RunningPaceSplitCalculatorI18n,
  },
  home: {
    nav: {
      Astrology: 'జ్యోతిష్యం',
      Finance: 'ఫైనాన్స్',
      Sports: 'క్రీడలు',
      Productivity: 'సమయం ఆదా చేసే సాధనాలు',
      'Sashtashtaga Dosha Calculator': 'షష్టాష్టగ దోష కాలిక్యులేటర్',
      'Thirumana Porutham Calculator': 'తిరుమన పోరుతం కాలిక్యులేటర్',
      'Loan EMI Calculator': 'లోన్ EMI కాలిక్యులేటర్',
      'Running Pace Split Calculator': 'రన్నింగ్ పేస్ స్ప్లిట్ కాలిక్యులేటర్',
      'Screen Recorder': 'స్క్రీన్ రికార్డర్',
      allApps: 'అన్ని యాప్‌లు',
      apps: 'యాప్‌లు',
      home: 'హోమ్ పేజీ',
      about: 'గురించి',
      contact: 'సంప్రదించండి',
      privacyPolicy: 'గోప్యతా విధానం',
    },
  },
};

export default telugu;
