import type { ReactElement } from 'react';
import React from 'react';

import { useAppSelector } from '@/redux/hooks';

import Footer from './footer';
import Navigation from './navigation';
import { getCategoryNavState } from './slice';

type HeaderType = {
  children: ReactElement[];
};

const Layout: (props: HeaderType) => JSX.Element = ({ children }) => {
  const isCategoryMenuOpen = useAppSelector(getCategoryNavState);

  return (
    <>
      <Navigation />
      <div className="flex px-4 md:px-8 pt-20 mb-20 justify-end 2xl:container 2xl:mx-auto">
        <main
          className={`flex w-full ${
            isCategoryMenuOpen ? 'md:w-3/4 md:pl-10 lg:-w-full lg:pl-0' : ''
          } transition-all`}
        >
          <div className="flex flex-col md:flex-row p-1 py-4 w-full">
            {children}
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
