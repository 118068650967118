import categories from './categories';
import type { CategoryType } from './categories';

export interface MenuItemType {
  name: string;
  slug: string;
  category: string;
}

interface CategoryMenuItems extends CategoryType {
  apps: MenuItemType[];
}

const menuItems: MenuItemType[] = [
  {
    name: 'Sashtashtaga Dosha Calculator',
    slug: 'sashtashtaga-dosha-calculator',
    category: 'astrology',
  },
  {
    name: 'Thirumana Porutham Calculator',
    slug: 'thirumana-porutham-calculator',
    category: 'astrology',
  },
  {
    name: 'Loan EMI Calculator',
    slug: 'loan-emi-calculator',
    category: 'finance',
  },
  {
    name: 'Screen Recorder',
    slug: 'screen-recorder',
    category: 'productivity',
  },
  {
    name: 'Running Pace Split Calculator',
    slug: 'running-pace-split-calculator',
    category: 'sports',
  },
];

export const getMenus: () => CategoryMenuItems[] = () =>
  categories
    .map((category) => ({
      ...category,
      apps: menuItems.filter((menu) => menu.category === category.id),
    }))
    .filter((item) => item.apps.length > 0);

export default menuItems;
