import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { RootState } from '@/redux/store';
import { getDateStr } from '@/utilities/date';

import type { DetailsType } from './components/basicForm';
import { planets } from './components/planets';

export interface AarukuYettuBasicType extends DetailsType {
  kundli?: {
    [key: string]: number;
  };
  savedAt?: number;
}
export interface AarukuYettuHistoryType {
  groom: AarukuYettuBasicType[];
  bride: AarukuYettuBasicType[];
}

export interface AarukuYettuType {
  groom: AarukuYettuBasicType;
  bride: AarukuYettuBasicType;
  history: AarukuYettuHistoryType | any;
}

const initData = {
  name: '',
  dob: getDateStr(new Date(), 'iso'),
  risingPlanet: planets[0].name,
  risingYear: 1,
  risingMonth: 1,
  kundli: {},
};

const initialState: AarukuYettuType = {
  groom: initData,
  bride: initData,
  history: { groom: [], bride: [] },
};

export const aarukuYettuSlice = createSlice({
  name: 'apps/aaruku-yettu',
  initialState,
  reducers: {
    setGroom(state, action: PayloadAction<AarukuYettuBasicType>) {
      state.groom = { ...state.groom, ...action.payload };
    },
    setBride(state, action: PayloadAction<AarukuYettuBasicType>) {
      state.bride = { ...state.bride, ...action.payload };
    },
    setFullHistory(state, action: PayloadAction<AarukuYettuHistoryType>) {
      state.history = action.payload;
    },
    setPersonHistory(state, action: PayloadAction<string>) {
      const savedAt = new Date().getTime();
      state.history = {
        ...state.history,
        [action.payload]: [
          ...state.history[action.payload],
          action.payload === 'groom'
            ? { ...state.groom, savedAt }
            : { ...state.bride, savedAt },
        ],
      };
      localStorage.setItem('app/aarukuYettu', JSON.stringify(state.history));
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.apps.aarukuYettu,
    }),
  },
});

export const { setGroom, setBride, setPersonHistory, setFullHistory } =
  aarukuYettuSlice.actions;

export const getState: (state: RootState) => AarukuYettuType = (state) =>
  state.apps.aarukuYettu;

export default aarukuYettuSlice.reducer;
