import { combineReducers } from '@reduxjs/toolkit';

import loanEmiCalculator from './loan-emi-calculator/slice';
import runningPaceCalculator from './running-pace-split-calculator/slice';
import aarukuYettu from './sashtashtaga-dosha-calculator/slice';
import thirumanaPorutham from './thirumana-porutham-calculator/slice';

const appsReducer = combineReducers({
  aarukuYettu,
  loanEmiCalculator,
  runningPaceCalculator,
  thirumanaPorutham,
});

export default appsReducer;
