import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { RootState } from '@/redux/store';

interface DurationType {
  hour: number;
  min: number;
  sec: number;
}

export interface BasicInputType {
  distance: number;
  duration: DurationType;
  metric: string;
  variation: number;
}

export interface StateType extends BasicInputType {
  time: number;
}

const initialState = {
  distance: 10,
  time: 3600,
  metric: 'km',
  duration: {
    hour: 1,
    min: 0,
    sec: 0,
  },
  variation: 0,
};

const slice = createSlice({
  name: 'app/running-pace-calculator',
  initialState,
  reducers: {
    setData(state: StateType, action: PayloadAction<StateType>) {
      state.distance = parseFloat(`${action.payload.distance}`);
      state.duration = action.payload.duration;
      state.time =
        action.payload.duration.hour * 3600 +
        action.payload.duration.min * 60 +
        action.payload.duration.sec;
      state.metric = action.payload.metric;
      state.variation = action.payload.variation;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.apps.runningPaceCalculator,
    }),
  },
});

export const { setData } = slice.actions;

export const getState: (state: RootState) => StateType = (state) =>
  state.apps.runningPaceCalculator;

export default slice.reducer;
