import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Footer: () => JSX.Element = () => (
  <footer className="w-full border-t-2 border-t-gray-800 p-4">
    <div className="flex-col justify-center">
      <ul className="flex w-full mx-auto justify-center">
        <Link href="/">
          <li className="px-4 pl-0 hover:text-pink-400 cursor-pointer">
            <FormattedMessage id="home.nav.home" />
          </li>
        </Link>
        <Link href="/about/">
          <li className="px-4 hover:text-pink-400 cursor-pointer">
            <FormattedMessage id="home.nav.about" />
          </li>
        </Link>
        <Link href="/contact/">
          <li className="px-4 hover:text-pink-400 cursor-pointer">
            <FormattedMessage id="home.nav.contact" />
          </li>
        </Link>
        <Link href="/privacy-policy/">
          <li className="px-4 pr-0 hover:text-pink-400 cursor-pointer">
            <FormattedMessage id="home.nav.privacyPolicy" />
          </li>
        </Link>
      </ul>
      <p className="text-center mt-2">&copy; 2023 - Thandhu.com</p>
    </div>
  </footer>
);

export default Footer;
