const defaultSEO = {
  title: 'Thandhu',
  titleTemplate: '%s | Thandhu',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://www.thandhu.com/',
    site_name: 'Thandhu',
  },
  twitter: {
    handle: '@thandhu',
    site: '@thandhu',
    cardType: 'summary_large_image',
  },
};

export default defaultSEO;
