import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getMenus } from './menuItems';

interface CategoryMenuType {
  onCloseMenu: () => void;
}

const CategoryMenu: (props: CategoryMenuType) => JSX.Element = ({
  onCloseMenu,
}: CategoryMenuType) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [expandedCategory, setExpandedCategory] = useState('astrology');

  const handleClickOutside: (event: Event) => void = (event: Event) => {
    if (
      event.target instanceof HTMLDivElement &&
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      onCloseMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const router = useRouter();
  const { slug } = router.query;

  return (
    <div
      className="flex flex-col top-20 left-3 md:left-7 border-b-cyan-900 rounded-xl w-11/12 md:w-1/4 bg-gradient-to-b from-[#1d3543] via-slate-700 to-slate-900 fixed -mt-4 pb-3 shadow-lg shadow-slate-900"
      ref={menuRef}
    >
      <div className="h-8 w-8 -z-10 bg-[#1d3543] absolute left-2 -top-1 rotate-45"></div>
      <div className="text-xl font-bold px-3 pt-3 text-slate-400 flex justify-between">
        <div>
          <FormattedMessage id="home.nav.apps" />
        </div>
        <div
          className="cursor-pointer font-sm"
          role="presentation"
          onClick={onCloseMenu}
        >
          ╳
        </div>
      </div>
      {/* <div className="p-3">
        <input
          type="text"
          placeholder="Search apps..."
          className="w-full p-3 rounded bg-gray-800 text-fill-white"
        />
      </div> */}
      <div>
        {getMenus().map((menu) => (
          <>
            <div
              className="font-bold px-4 py-3 cursor-pointer"
              role="presentation"
              onClick={(): void => setExpandedCategory(menu.id)}
            >
              <FormattedMessage id={`home.nav.${menu.name}`} />
            </div>
            {expandedCategory === menu.id && (
              <ul
                className={`flex flex-col ${
                  expandedCategory === menu.id ? 'h-auto' : 'h-0'
                } transition-all`}
              >
                {menu.apps.map((app) => (
                  <Link href={`/app/${app.slug}`} key={app.slug}>
                    <button type="button" onClick={onCloseMenu}>
                      <li
                        className={
                          `pl-8 py-1 ${
                            app.slug === slug
                              ? 'bg-pink-800 hover:text-fill-slate-50 hover:bg-pink-900'
                              : 'hover:bg-pink-800'
                          } ` +
                          `border-b-2 border-b-slate-600 ` +
                          `cursor-pointer text-left`
                        }
                      >
                        <FormattedMessage id={`home.nav.${app.name}`} />
                      </li>
                    </button>
                  </Link>
                ))}
              </ul>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default CategoryMenu;
