import { English as LoanEmiCalculatorI18n } from '@/apps/loan-emi-calculator/i18n';
import { English as RunningPaceSplitCalculatorI18n } from '@/apps/running-pace-split-calculator/i18n';
import { English as SashtashtagaDoshaCalculatorI18n } from '@/apps/sashtashtaga-dosha-calculator/i18n';
import { English as ScreenRecorderI18n } from '@/apps/screen-recorder/i18n';
import { English as ThirumanaPoruthamI18n } from '@/apps/thirumana-porutham-calculator/i18n';

const english = {
  app: {
    thirumanaPoruthamCalculator: ThirumanaPoruthamI18n,
    sashtashtagaDoshaCalculator: SashtashtagaDoshaCalculatorI18n,
    loanEmiCalculator: LoanEmiCalculatorI18n,
    screenRecorder: ScreenRecorderI18n,
    runningPaceSplitCalculator: RunningPaceSplitCalculatorI18n,
  },
  home: {
    nav: {
      Astrology: 'Astrology',
      Finance: 'Finance',
      Sports: 'Sports',
      Productivity: 'Productivity',
      'Sashtashtaga Dosha Calculator': 'Sashtashtaga Dosha Calculator',
      'Thirumana Porutham Calculator': 'Thirumana Porutham Calculator',
      'Loan EMI Calculator': 'Loan EMI Calculator',
      'Running Pace Split Calculator': 'Running Pace Split Calculator',
      'Screen Recorder': 'Screen Recorder',
      allApps: 'All Apps',
      apps: 'Apps',
      home: 'Home',
      about: 'About',
      contact: 'Contact',
      privacyPolicy: 'Privacy Policy',
    },
  },
};

export default english;
