import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/redux/hooks';

import CategoryMenu from '../category-menu';
import { setIsCategoryMenuOpen, getCategoryNavState } from '../slice';

const Navigation: () => JSX.Element = () => {
  const isCategoryMenuOpen = useAppSelector(getCategoryNavState);
  const dispatch = useAppDispatch();

  // const [isCategoryOpen, setIsCategoryOpen] = useState(true)

  const toggleCategoryMenu: () => void = () => {
    dispatch(setIsCategoryMenuOpen(!isCategoryMenuOpen));
  };

  return (
    <nav className="py-3 fixed w-full backdrop-blur z-10">
      <div className="px-4 md:px-8 flex justify-between">
        <div className="flex">
          <Image
            src="/menu.png"
            height={30}
            width={40}
            alt="All Apps"
            className={`cursor-pointer ${
              isCategoryMenuOpen ? '-rotate-45' : ''
            } transition-all`}
            onClick={toggleCategoryMenu}
          />
          {isCategoryMenuOpen && (
            <CategoryMenu onCloseMenu={toggleCategoryMenu} />
          )}
          <Link href="/">
            <div className="flex cursor-pointer ml-3">
              <Image
                src="/logo.svg"
                height={40}
                width={40}
                alt="Thandhu Logo"
                className="animate-[spin_15s_ease-in-out_infinite]"
              />
              <span className="font-bungee my-auto text-3xl ml-3 bg-clip-text bg-gradient-to-r from-[#9950AA] to-[#e86a22] text-fill-transparent">
                Thandhu
              </span>
            </div>
          </Link>
        </div>
        <ul className="hidden md:flex my-auto">
          <Link href="/">
            <li className="px-4 pl-0 hover:text-pink-400 cursor-pointer">
              <FormattedMessage id="home.nav.home" />
            </li>
          </Link>
          <Link href="/about/">
            <li className="px-4 hover:text-pink-400 cursor-pointer">
              <FormattedMessage id="home.nav.about" />
            </li>
          </Link>
          <Link href="/contact/">
            <li className="px-4 hover:text-pink-400 cursor-pointer">
              <FormattedMessage id="home.nav.contact" />
            </li>
          </Link>
          <Link href="/privacy-policy/">
            <li className="px-4 pr-0 hover:text-pink-400 cursor-pointer">
              <FormattedMessage id="home.nav.privacyPolicy" />
            </li>
          </Link>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
