import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export interface MenuType {
  isCategoryMenuOpen: boolean;
}

const initialState: MenuType = {
  isCategoryMenuOpen: false,
};

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setIsCategoryMenuOpen(state, action: PayloadAction<boolean>) {
      state.isCategoryMenuOpen = action.payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.nav,
    }),
  },
});

// export actions
export const { setIsCategoryMenuOpen } = navSlice.actions;

// get nav state
export const getCategoryNavState: (state: { nav: MenuType }) => boolean = (
  state
) => state.nav.isCategoryMenuOpen;

// export reducer
const navSliceReducer = { navSlice: navSlice.reducer };
export { navSliceReducer };
