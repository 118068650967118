import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { RootState } from '@/redux/store';

export interface StateType {
  principal: number;
  interest: number;
  years: number;
  currency: string;
}

const initialState = {
  principal: 50000,
  interest: 5,
  years: 2,
  currency: '₹',
};

const slice = createSlice({
  name: 'app/loan-emi-calculator',
  initialState,
  reducers: {
    setData(state: StateType, action: PayloadAction<StateType>) {
      state.principal = parseInt(`${action.payload.principal || 0}`, 10);
      state.interest = parseFloat(`${action.payload.interest}`);
      state.years = parseInt(`${action.payload.years}`, 10);
      state.currency = action.payload.currency;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.apps.loanEmiCalculator,
    }),
  },
});

export const { setData } = slice.actions;

export const getState: (state: RootState) => StateType = (state) =>
  state.apps.loanEmiCalculator;

export default slice.reducer;
