import { Malayalam as LoanEmiCalculatorI18n } from '@/apps/loan-emi-calculator/i18n';
import { Malayalam as RunningPaceSplitCalculatorI18n } from '@/apps/running-pace-split-calculator/i18n';
import { Malayalam as SashtashtagaDoshaCalculatorI18n } from '@/apps/sashtashtaga-dosha-calculator/i18n';
import { Malayalam as ScreenRecorderI18n } from '@/apps/screen-recorder/i18n';
import { Malayalam as ThirumanaPoruthamI18n } from '@/apps/thirumana-porutham-calculator/i18n';

const malayalam = {
  app: {
    thirumanaPoruthamCalculator: ThirumanaPoruthamI18n,
    sashtashtagaDoshaCalculator: SashtashtagaDoshaCalculatorI18n,
    loanEmiCalculator: LoanEmiCalculatorI18n,
    screenRecorder: ScreenRecorderI18n,
    runningPaceSplitCalculator: RunningPaceSplitCalculatorI18n,
  },
  home: {
    nav: {
      Astrology: 'ജ്യോതിഷം',
      Finance: 'ധനകാര്യം',
      Sports: 'കായികം',
      Productivity: 'സമയം ലാഭിക്കൽ ഉപകരണങ്ങൾ',
      'Sashtashtaga Dosha Calculator': 'സഷ്ടാഷ്ടഗ ദോഷ കാൽക്കുലേറ്റർ',
      'Thirumana Porutham Calculator': 'തിരുമന പൊരുത്തം കാൽക്കുലേറ്റർ',
      'Loan EMI Calculator': 'ലോൺ EMI കാൽക്കുലേറ്റർ',
      'Running Pace Split Calculator': 'റണ്ണിംഗ് പേസ് സ്പ്ലിറ്റ് കാൽക്കുലേറ്റർ',
      'Screen Recorder': 'സ്ക്രീൻ റെക്കോർഡർ',
      allApps: 'എല്ലാ അപ്ലിക്കേഷനുകളും',
      apps: 'അപ്ലിക്കേഷനുകൾ',
      home: 'ഹോം പേജ്',
      about: 'കുറിച്ച്',
      contact: 'കോൺടാക്റ്റ്',
      privacyPolicy: 'സ്വകാര്യതാ നയം',
    },
  },
};

export default malayalam;
