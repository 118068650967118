export const getAge: (birthDate: Date) => number = (birthDate) => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getMonthDiff: (d1: Date, d2: Date) => number = (d1, d2) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getDateStr: (date: Date, format: string) => string = (
  date,
  format
) => {
  const appendZero = (value: number): string =>
    value < 10 ? `0${value}` : `${value}`;

  switch (format) {
    case 'mmmm, yyyy':
      return `${months[date.getMonth()]}, ${date.getFullYear()}`;
    case 'iso':
      return `${date.getFullYear()}-${appendZero(
        date.getMonth() + 1
      )}-${appendZero(date.getDate())}`;
    case 'dd/mm/yyyy':
      return `${date.getDate()}/${appendZero(
        date.getMonth() + 1
      )}/${date.getFullYear()}`;
    case 'dd mmmm, yyyy':
    default:
      return `${date.getDate()} ${
        months[date.getMonth()]
      }, ${date.getFullYear()}`;
  }
};

export const getTime: (seconds: number) => string = (seconds) => {
  if (seconds === Infinity || isNaN(seconds)) return `00:00:00`;
  const dur = new Date(seconds * 1000).toISOString().slice(11, 19);
  const durSplit = dur.split(':');
  if (durSplit[0] === '00') durSplit.splice(0, 1);
  if (durSplit[0] === '00') {
    durSplit.splice(0, 1);
    durSplit[0] = `${durSplit[0]}s`;
  }
  return durSplit.join(':');
};
