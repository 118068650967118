import { Hindi as LoanEmiCalculatorI18n } from '@/apps/loan-emi-calculator/i18n';
import { Hindi as RunningPaceSplitCalculatorI18n } from '@/apps/running-pace-split-calculator/i18n';
import { Hindi as SashtashtagaDoshaCalculatorI18n } from '@/apps/sashtashtaga-dosha-calculator/i18n';
import { Hindi as ScreenRecorderI18n } from '@/apps/screen-recorder/i18n';
import { Hindi as ThirumanaPoruthamI18n } from '@/apps/thirumana-porutham-calculator/i18n';

const hindi = {
  app: {
    thirumanaPoruthamCalculator: ThirumanaPoruthamI18n,
    sashtashtagaDoshaCalculator: SashtashtagaDoshaCalculatorI18n,
    loanEmiCalculator: LoanEmiCalculatorI18n,
    screenRecorder: ScreenRecorderI18n,
    runningPaceSplitCalculator: RunningPaceSplitCalculatorI18n,
  },
  home: {
    nav: {
      Astrology: 'ज्योतिष',
      Finance: 'वित्त',
      Sports: 'खेलकूद',
      Productivity: 'समय बचाने वाले उपकरण',
      'Sashtashtaga Dosha Calculator': 'साष्टाष्टग दोष कैलकुलेटर',
      'Thirumana Porutham Calculator': 'थिरुमना पोरुथम कैलकुलेटर',
      'Loan EMI Calculator': 'ऋृण EMI गणना-यंत्र',
      'Running Pace Split Calculator': 'रनिंग पेस स्प्लिट कैलकुलेटर',
      'Screen Recorder': 'स्क्रीन अभिलेखी',
      allApps: 'सभी एप्लीकेशन',
      apps: 'ऐप्स',
      home: 'मुख पृष्ठ',
      about: 'बारे में',
      contact: 'संपर्क',
      privacyPolicy: 'गोपनीयता नीति',
    },
  },
};

export default hindi;
