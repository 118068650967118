import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { RootState } from '@/redux/store';

export interface ThirumanaPoruthamType {
  raasi: string | null;
  natchathram: string | null;
}

export interface StateType {
  groom: ThirumanaPoruthamType;
  bride: ThirumanaPoruthamType;
}

const initialState = {
  groom: {
    raasi: null,
    natchathram: null,
  },
  bride: {
    raasi: null,
    natchathram: null,
  },
};

const slice = createSlice({
  name: 'app/thirumana-porutham',
  initialState,
  reducers: {
    setGroomRaasi(state: StateType, action: PayloadAction<string | null>) {
      state.groom.raasi = action.payload;
    },
    setGroomNatchathram(
      state: StateType,
      action: PayloadAction<string | null>
    ) {
      state.groom.natchathram = action.payload;
    },
    setBrideRaasi(state: StateType, action: PayloadAction<string | null>) {
      state.bride.raasi = action.payload;
    },
    setBrideNatchathram(
      state: StateType,
      action: PayloadAction<string | null>
    ) {
      state.bride.natchathram = action.payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.apps.thirumanaPorutham,
    }),
  },
});

export const {
  setGroomRaasi,
  setGroomNatchathram,
  setBrideRaasi,
  setBrideNatchathram,
} = slice.actions;

export const getState: (state: RootState) => StateType = (state) =>
  state.apps.thirumanaPorutham;

export default slice.reducer;
