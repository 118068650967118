export interface PlanetType {
  name: string;
  years: number;
  color?: string;
}

const planets: PlanetType[] = [
  {
    name: 'suriyan',
    years: 6 * 12,
    color: '#ffc700',
  },
  {
    name: 'chandran',
    years: 10 * 12,
    color: '#6e6e64',
  },
  {
    name: 'sevvai',
    years: 7 * 12,
    color: '#bf2615',
  },
  {
    name: 'raahu',
    years: 18 * 12,
    color: '#26709e',
  },
  {
    name: 'guru',
    years: 16 * 12,
    color: '#bd8235',
  },
  {
    name: 'sani',
    years: 19 * 12,
    color: '#b09b80',
  },
  {
    name: 'pudhan',
    years: 17 * 12,
    color: '#820000',
  },
  {
    name: 'kedhu',
    years: 7 * 12,
    color: '#757575',
  },
  {
    name: 'sukran',
    years: 20 * 12,
    color: '#fafafa',
  },
];

let timeline: string[] = [];
planets.forEach((planet) => {
  timeline = timeline.concat(
    Array.from(
      {
        length: planet.years,
      },
      () => planet.name
    )
  );
});

export { timeline, planets };
