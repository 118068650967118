import 'normalize.css';
import '@/styles/globals.css';
import { initializeApp } from 'firebase/app';
import { DefaultSeo } from 'next-seo';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import type { NextComponentType, NextPageContext } from 'next/types';
import { GoogleAnalytics, event } from 'nextjs-google-analytics';
import React from 'react';
import { Provider } from 'react-redux';

import firebaseConfig from '@/firebase/config';
import I18nProvider from '@/i18n/provider';
import Layout from '@/layout';
import { store } from '@/redux/store';
import type ISeo from '@/types/seo';

import SEO from '../next-seo.config';

interface AppPropsWithSEO extends AppProps {
  Component: ISeo & NextComponentType<NextPageContext, any, any>;
}

export const reportWebVitals: (metric: NextWebVitalsMetric) => void = (
  metric
) => {
  const { id, name, label, value } = metric;

  event(name, {
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
};

const MyApp: (props: AppPropsWithSEO) => JSX.Element = (props) => {
  const { Component, pageProps } = props;

  initializeApp(firebaseConfig);

  return (
    <I18nProvider>
      <Provider store={store}>
        <Layout>
          <h1>{process.env.ENVIRONMENT}</h1>
          <DefaultSeo {...SEO} />
          <Component {...pageProps} />
          <GoogleAnalytics />
        </Layout>
      </Provider>
    </I18nProvider>
  );
};

export default MyApp;
