import { Tamil as LoanEmiCalculatorI18n } from '@/apps/loan-emi-calculator/i18n';
import { Tamil as RunningPaceSplitCalculatorI18n } from '@/apps/running-pace-split-calculator/i18n';
import { Tamil as SashtashtagaDoshaCalculatorI18n } from '@/apps/sashtashtaga-dosha-calculator/i18n';
import { Tamil as ScreenRecorderI18n } from '@/apps/screen-recorder/i18n';
import { Tamil as ThirumanaPoruthamI18n } from '@/apps/thirumana-porutham-calculator/i18n';

const tamil = {
  app: {
    thirumanaPoruthamCalculator: ThirumanaPoruthamI18n,
    sashtashtagaDoshaCalculator: SashtashtagaDoshaCalculatorI18n,
    loanEmiCalculator: LoanEmiCalculatorI18n,
    screenRecorder: ScreenRecorderI18n,
    runningPaceSplitCalculator: RunningPaceSplitCalculatorI18n,
  },
  home: {
    nav: {
      Astrology: 'ஜோதிடம்',
      Finance: 'நிதி',
      Sports: 'விளையாட்டு',
      Productivity: 'நேர சேமிப்பு கருவிகள்',
      'Sashtashtaga Dosha Calculator': 'சஷ்டாஷ்டக தோஷ கால்குலேட்டர்',
      'Thirumana Porutham Calculator': 'திருமணப் பொறுத்தம் கால்குலேட்டர்',
      'Loan EMI Calculator': 'கடனுக்கான EMI கால்குலேட்டர்',
      'Running Pace Split Calculator': 'ரன்னிங் பேஸ் ஸ்பிளிட் கால்குலேட்டர்',
      'Screen Recorder': 'ஸ்கிரீன் ரெக்கார்டர்',
      allApps: 'அனைத்து பயன்பாடுகள்',
      apps: 'பயன்பாடுகள்',
      home: 'முகப்பு பக்கம்',
      about: 'பற்றி',
      contact: 'தொடர்பு',
      privacyPolicy: 'தனியுரிமைக் கொள்கை',
    },
  },
};

export default tamil;
